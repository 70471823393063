/*------ 22. contact Page  ------*/

.contact-map {
  height: 560px;
  position: relative;

  @media #{$xs-layout} {
    height: 400px;
  }
}



.contact-info-wrap {
  background-color: #f3f3f3;
  padding: 120px 70px 116px 90px;

  @media #{$lg-layout} {
    padding: 120px 20px 116px 40px;
  }

  @media #{$md-layout} {
    padding: 120px 20px 116px 30px;
  }

  @media #{$xs-layout} {
    padding: 50px 20px 45px 30px;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .single-contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .contact-icon {
      margin-right: 20px;

      i {
        font-size: 20px;
        color: #252525;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #252525;
        text-align: center;
        border-radius: 100%;
        transition: all 0.3s ease 0s;
      }
    }

    .contact-info-dec {
      p {
        line-height: 1;
        color: #404040;
        margin: 0 0 9px;

        a {
          color: #404040;

          &:hover {
            color: $theme-color;
          }
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &:hover .contact-icon i {
      background-color: #252525;
      color: #fff;
    }
  }
}

.contact-social {
  margin-top: 58px;

  h3 {
    font-weight: 500;
    color: #4d4d4d;
    font-size: 24px;
    margin: 0 0 17px;
    line-height: 1;
  }

  ul {
    li {
      margin: 0 10px;
      display: inline-block;

      a {
        font-size: 16px;
        color: #4d4d4d;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}

.contact-form {
  // background-color: #f3f3f3;
  background-color: transparent;
  padding: 20px 110px 50px 110px;

  // @media #{$lg-layout} {
  //   padding: 20px 50px 50px 50px;
  // }

  // @media #{$md-layout} {
  //   padding: 50px 30px 50px 30px;
  // }

  @media #{$xs-layout} {
    padding: 50px 15px 50px 15px;
  }

  .contact-title {
    h2 {
      // font-size: 24px;
      // font-weight: 500;
      // color: #464646;
      // line-height: 1;
      margin-bottom: 50px;
      font-size: 50px;
      line-height: 60px;
      font-weight: bold;
      color: #E5322D;
      text-align: center;
    }
  }

  .contact-form-style {

    input,
    textarea {
      background: #FBFBFB;
      border: 1px solid rgba(112, 112, 112, 0.3);
      border-radius: 6px;
      height: 50px;
      padding: 2px 14px;
      margin-bottom: 30px;
      color: #000;
      font-family: "Poppins", sans-serif;
      font-size: 18px;

      &::placeholder {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        color: rgba(75, 75, 75, 0.2);
      }
    }

    textarea {
      padding: 20px 14px;
      margin-bottom: 0px;
      height: 175px;
      resize: none;
    }

    button {
      border: none;
      background-color: #E53934;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1;
      padding: 15px 52px;
      margin-top: 38px;

      &:hover {
        background-color: $theme-color;
      }
    }
  }

  p {
    color: #333;

    &.success {
      margin-top: 10px;
    }
  }
}

.contact-form-style {
  .row {
    & div[class^="col-"] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.contact-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}


/**** Added Style ***/


.contact-top-container {
  padding: 0 100px 120px;


  .nav-pills .nav-link {
    border-radius: 12px;
    height: 55px;
    display: flex;
    align-items: center;
    margin: 8px 0;
    background-color: #F3F3F3;
    color: #464545;
    font-size: 18px;
    font-weight: bold;

  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #E53934;
  }

  .tab-content {
    .tab-pane {
      min-height: 400px;
    }
  }

  .tab-nav-col {
    align-items: center;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .flex-column.nav.nav-pills {
    width: 100%;
  }

  .contact-iframe-contaienr {
    iframe {
      position: absolute;
      width: 95%;
      height: 100%;
      border-radius: 12px;
    }
  }


}

.separator {
  position: absolute;
  left: 0vw;
  width: 100%;
  margin-top: -50px;
  border-top: 1px solid rgba(112, 112, 112, 0.18);
}

.contact-form-bottom-container {
  .contact-form {
    .submit-btn-container {
      display: flex;
      justify-content: center;

      button.submit {
        width: 250px;
        height: 60px;
        border-radius: 10px;
      }
    }

    .form-error {
      position: absolute;
      bottom: 5px;
      width: 100%;
      padding-left: 15px;
      color: #E53934;
    }

    .form-error.message-required {
      bottom: 63px !important;
    }
  }
}

.contact-page-social-icons-mega-container {
  width: 100%;
  height: 126px;
  background-color: #4b4b4b;
  display: flex;
  justify-content: center;

  .secondary-container {
    width: 500px;
    height: 100%;
    display: flex;
    align-items: center;

    .main-container {
      height: 58px;

      .row {
        height: 100%;

        .icon-col {
          height: 100%;
        }
      }

      .icon-container {
        height: 100%;
        display: flex;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;

        img {
          height: 100%;
        }
      }
    }
  }
}


@media #{$xs-layout} {
  .contact-top-container {
    padding: 0 20px 75px;

    .contact-iframe-contaienr {
      display: flex;
      justify-content: center;

      iframe {
        width: auto;
        max-width: 100%;
      }
    }
  }


  .custom-col-padding-on-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .contact-page-social-icons-mega-container {
    // height: 140px;
    // padding-top: 7px;
    // padding-bottom: 7px;

    .secondary-container {
      width: 100%;
      // height: 100%;

      .main-container {
        // height: 100%;

        .row {
          // height: 50%;
        }
      }
    }
  }
}