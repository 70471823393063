@font-face {
  font-family: 'NeoSans-Medium';
  src: url('../fonts/NeoSans-Medium.eot');
  src: local('Neo Sans Medium'), local('NeoSans-Medium'),
    url('../fonts/NeoSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NeoSans-Medium.woff2') format('woff2'),
    url('../fonts/NeoSans-Medium.woff') format('woff'),
    url('../fonts/NeoSans-Medium.ttf') format('truetype'),
    url('../fonts/NeoSans-Medium.svg#NeoSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansArabic-Light';
  src: url('../fonts/NeoSansArabic-Light.eot');
  src: local('Neo Sans Arabic Light'), local('NeoSansArabic-Light'),
    url('../fonts/NeoSansArabic-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NeoSansArabic-Light.woff2') format('woff2'),
    url('../fonts/NeoSansArabic-Light.woff') format('woff'),
    url('../fonts/NeoSansArabic-Light.ttf') format('truetype'),
    url('../fonts/NeoSansArabic-Light.svg#NeoSansArabic-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansArabic-Bold';
  src: url('../fonts/NeoSansArabic-Bold.eot');
  src: local('Neo Sans Arabic Bold'), local('NeoSansArabic-Bold'),
    url('../fonts/NeoSansArabic-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NeoSansArabic-Bold.woff2') format('woff2'),
    url('../fonts/NeoSansArabic-Bold.woff') format('woff'),
    url('../fonts/NeoSansArabic-Bold.ttf') format('truetype'),
    url('../fonts/NeoSansArabic-Bold.svg#NeoSansArabic-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansArabic';
  src: url('../fonts/NeoSansArabic.eot');
  src: local('Neo Sans Arabic Regular'), local('NeoSansArabic'),
    url('../fonts/NeoSansArabic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NeoSansArabic.woff2') format('woff2'),
    url('../fonts/NeoSansArabic.woff') format('woff'),
    url('../fonts/NeoSansArabic.ttf') format('truetype'),
    url('../fonts/NeoSansArabic.svg#NeoSansArabic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansArabic-Black';
  src: url('../fonts/NeoSansArabic-Black.eot');
  src: local('Neo Sans Arabic Black'), local('NeoSansArabic-Black'),
    url('../fonts/NeoSansArabic-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NeoSansArabic-Black.woff2') format('woff2'),
    url('../fonts/NeoSansArabic-Black.woff') format('woff'),
    url('../fonts/NeoSansArabic-Black.ttf') format('truetype'),
    url('../fonts/NeoSansArabic-Black.svg#NeoSansArabic-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansArabic-Medium';
  src: url('../fonts/NeoSansArabic-Medium.eot');
  src: local('Neo Sans Arabic Medium'), local('NeoSansArabic-Medium'),
    url('../fonts/NeoSansArabic-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NeoSansArabic-Medium.woff2') format('woff2'),
    url('../fonts/NeoSansArabic-Medium.woff') format('woff'),
    url('../fonts/NeoSansArabic-Medium.ttf') format('truetype'),
    url('../fonts/NeoSansArabic-Medium.svg#NeoSansArabic-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansArabic-Ultra';
  src: url('../fonts/NeoSansArabic-Ultra.eot');
  src: local('Neo Sans Arabic Ultra'), local('NeoSansArabic-Ultra'),
    url('../fonts/NeoSansArabic-Ultra.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NeoSansArabic-Ultra.woff2') format('woff2'),
    url('../fonts/NeoSansArabic-Ultra.woff') format('woff'),
    url('../fonts/NeoSansArabic-Ultra.ttf') format('truetype'),
    url('../fonts/NeoSansArabic-Ultra.svg#NeoSansArabic-Ultra') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Rochester';
  src: url('../fonts/Rochester-Regular.eot');
  src: url('../fonts/Rochester-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rochester-Regular.woff2') format('woff2'),
    url('../fonts/Rochester-Regular.woff') format('woff'),
    url('../fonts/Rochester-Regular.ttf') format('truetype'),
    url('../fonts/Rochester-Regular.svg#Rochester-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Foundry-Font';
  src: url('../fonts/FoundryFontPackRegular.eot');
  src: url('../fonts/FoundryFontPackRegular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/FoundryFontPackRegular.woff2') format('woff2'),
    url('../fonts/FoundryFontPackRegular.woff') format('woff'),
    url('../fonts/FoundryFontPackRegular.ttf') format('truetype'),
    url('../fonts/FoundryFontPackRegular.svg#FoundryFontPackRegular') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Himalaya';
  src: url('../fonts/Himalaya-Regular.eot');
  src: url('../fonts/Himalaya-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Himalaya-Regular.woff2') format('woff2'),
    url('../fonts/Himalaya-Regular.woff') format('woff'),
    url('../fonts/Himalaya-Regular.ttf') format('truetype'),
    url('../fonts/Himalaya-Regular.svg#Himalaya-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('../fonts/Cairo-Bold.eot');
  src: url('../fonts/Cairo-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Cairo-Bold.woff2') format('woff2'),
      url('../fonts/Cairo-Bold.woff') format('woff'),
      url('../fonts/Cairo-Bold.ttf') format('truetype'),
      url('../fonts/Cairo-Bold.svg#Cairo-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('../fonts/Cairo-Black.eot');
  src: url('../fonts/Cairo-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Cairo-Black.woff2') format('woff2'),
      url('../fonts/Cairo-Black.woff') format('woff'),
      url('../fonts/Cairo-Black.ttf') format('truetype'),
      url('../fonts/Cairo-Black.svg#Cairo-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('../fonts/Cairo-Light.eot');
  src: url('../fonts/Cairo-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Cairo-Light.woff2') format('woff2'),
      url('../fonts/Cairo-Light.woff') format('woff'),
      url('../fonts/Cairo-Light.ttf') format('truetype'),
      url('../fonts/Cairo-Light.svg#Cairo-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('../fonts/Cairo-ExtraLight.eot');
  src: url('../fonts/Cairo-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Cairo-ExtraLight.woff2') format('woff2'),
      url('../fonts/Cairo-ExtraLight.woff') format('woff'),
      url('../fonts/Cairo-ExtraLight.ttf') format('truetype'),
      url('../fonts/Cairo-ExtraLight.svg#Cairo-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('../fonts/Cairo-Regular.eot');
  src: url('../fonts/Cairo-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Cairo-Regular.woff2') format('woff2'),
      url('../fonts/Cairo-Regular.woff') format('woff'),
      url('../fonts/Cairo-Regular.ttf') format('truetype'),
      url('../fonts/Cairo-Regular.svg#Cairo-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('../fonts/Cairo-SemiBold.eot');
  src: url('../fonts/Cairo-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Cairo-SemiBold.woff2') format('woff2'),
      url('../fonts/Cairo-SemiBold.woff') format('woff'),
      url('../fonts/Cairo-SemiBold.ttf') format('truetype'),
      url('../fonts/Cairo-SemiBold.svg#Cairo-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}







@import "~bootstrap/scss/bootstrap.scss";
@import "~swiper/css/swiper.css";
@import "~animate.css/animate.min.css";
@import "~lightgallery.js/dist/css/lightgallery.css";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "order";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "home";
@import "thank-you";
@import "arabic-style";