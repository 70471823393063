/** Hide by Default Show in arabic only ***/

.hide-default-show-in-arabic {
    display: none;
}

.product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span::before {
    display: none !important;
}

.product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 {
    span {
        margin-right: 0;

        .price-number {
            margin-right: 5px;
        }
    }
}

.arabic-style {
    direction: rtl;


    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
        font-family: 'Cairo', sans-serif !important;
        letter-spacing: 0 !important;
    }

    font-family: 'Cairo',
    sans-serif;

    .header-top-area {
        direction: rtl;
    }

    .language-currency-wrap .same-language-currency {
        margin-right: auto;
        margin-left: 54px;
    }

    .language-currency-wrap .same-language-currency:before {
        right: auto;
        left: -27px;
    }

    .language-currency-wrap .same-language-currency .lang-car-dropdown {
        left: auto;
        right: 0;
        text-align: right;
    }

    .language-currency-wrap .same-language-currency p {
        direction: ltr;
    }

    .header-mega-container {
        direction: rtl;
    }

    .header-right-wrap .same-style.cart-wrap button span.count-style,
    .header-right-wrap .same-style.cart-wrap>a span.count-style,
    .header-right-wrap .same-style.header-wishlist button span.count-style,
    .header-right-wrap .same-style.header-wishlist>a span.count-style,
    .header-right-wrap .same-style.header-compare button span.count-style,
    .header-right-wrap .same-style.header-compare>a span.count-style {
        right: auto;
        left: -18px;
    }

    .single-banner .banner-position-hm15-2 {
        left: auto;
        right: 40px;
        text-align: right;
    }

    .single-banner .banner-content-4.pink-banner h2 {
        text-align: right;
    }

    .single-banner .banner-content-4 h5 {
        text-align: right;
    }

    .single-banner .banner-content-3>a {

        transform: rotateY(180deg);
    }

    .header-right-wrap .same-style.cart-wrap .shopping-cart-content,
    .header-right-wrap .same-style.header-wishlist .shopping-cart-content,
    .header-right-wrap .same-style.header-compare .shopping-cart-content {
        left: 0;
        right: auto;
        direction: ltr;
    }

    li.single-shopping-cart {
        transform: scaleX(-1);

        div {
            transform: scaleX(-1);
        }

        .shopping-cart-title {
            text-align: right;
        }
    }

    .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-title h4,
    .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-title h4,
    .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-title h4 {
        margin-right: 0;
    }

    .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-title h6,
    .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-title h6,
    .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-title h6 {
        display: flex;
        flex-direction: row-reverse;

        span {
            padding: 0 2px;
        }
    }

    .header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li .shopping-cart-title span,
    .header-right-wrap .same-style.header-wishlist .shopping-cart-content ul li .shopping-cart-title span,
    .header-right-wrap .same-style.header-compare .shopping-cart-content ul li .shopping-cart-title span.price-cart-menu {
        display: flex;
        flex-direction: row-reverse;

        span {
            padding: 0 2px;
        }
    }

    .hide-this-in-arabic {
        display: none !important;
    }

    h4.hide-default-show-in-arabic {
        display: flex;
        justify-content: space-between;

        .pound {
            margin-right: 5px;
        }
    }

    @media only screen and (max-width: 991px) {
        .offcanvas-mobile-menu {
            right: auto;
            left: 0;
            transform: translateX(-100%);
            padding-left: 0;
            padding-right: 60px;
        }

        .offcanvas-mobile-menu.active {
            transform: translateX(0);
        }

        .offcanvas-menu-close {
            left: auto;
            right: 0;
        }
    }


    .offcanvas-mobile-menu {
        .offcanvas-navigation ul li.menu-item-has-children a {
            text-align: right;
        }

        .mobile-menu-middle {
            text-align: right;
        }

        .off-canvas-contact-widget .header-contact-info__list li {
            margin-right: 0;
            margin-left: 25px;
        }

        .off-canvas-contact-widget .header-contact-info__list li:last-child {
            margin-left: 0;
        }

        .off-canvas-contact-widget .header-contact-info__list li i {
            margin-right: 0;
            margin-left: 5px;
            transform: rotateY(-180deg);
        }

        .off-canvas-widget-social {
            text-align: right;
        }

        .off-canvas-widget-social a:first-child {
            margin-left: 10px;
            margin-right: 0;
        }
    }

    .support-wrap-3>.subscribe-form {
        div.clear {
            border: none !important;
        }
    }

    .product-wrap-2 .product-content-2 .title-price-wrap-2 h3 {
        text-align: right;

        a {
            text-align: right;
        }
    }

    .product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 {
        text-align: right;

        >span {
            margin-right: 0;
            margin-left: 17px;
            display: flex;

            .price-number {
                margin-right: 0;
                margin-left: 5px;
            }
        }
    }

    .product-details-content h2 {
        text-align: right;
    }

    .footer-links-title {
        text-align: right;
    }

    .footer-list-links {
        ul {
            li {
                text-align: right;
            }
        }
    }

    .subscribe-form .mc-form>div:last-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .scroll-top {
        right: 0;
        left: 20px;
    }

    .whats-app {
        right: 0;
        left: 100px;
    }

    .copyRights {
        direction: ltr;
    }

    .single-category h4 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .sidebar-style {
        margin-right: 0;
        margin-left: 30px;
    }

    .sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left label .radio-button {
        margin-right: 0;
        margin-left: 10px;
    }

    h4.pro-sidebar-title {
        text-align: right;
    }

    .product-details-content p {
        text-align: right;
    }

    .product-details-content .pro-details-social ul li {
        margin-left: 40px;
        margin-right: 0;
    }

    .product-details-content .pro-details-social ul li:last-child {
        margin-left: 0px;
    }

    .about-top-right-col {
        text-align: right;

        @media only screen and (min-width: 768px) {
            p.info-text {
                padding-left: 30px;
                padding-right: 0 !important;
            }
        }

    }

    .single-about-info-container {
        text-align: right;

        p.text {
            padding-right: 0 !important;
            padding-left: 20px;
        }
    }

    p.form-error {
        text-align: right;
    }

    .cart-page-title{
        text-align: right;
    }

    .cart-table-content table tbody > tr td {
        padding: 30px 0 30px 0px;
        text-align: center;
    }

    .cart-table-content table tbody > tr td.product-thumbnail{
        padding: 30px 20px 30px 10px;
    }

    .title-wrap {
        justify-content: flex-start;
        display: flex;
    }

    .grand-totall .title-wrap h4.cart-bottom-title {
        padding-right: 0;
        padding-left: 18px;
    }

    .grand-totall h5 {
        display: flex;
        justify-content: space-between;
    }

    .grand-totall h4.grand-totall-title {
        display: flex;
        justify-content: space-between;
    }

    .billing-info-wrap {
        text-align: right;
    }

    .billing-info-wrap label input {
        margin-right: 0;
        margin-left: 10px;
    }

    .your-order-area {
        text-align: right;
    }

    .single-my-account h3.panel-title{
        text-align: right;
    }

    .single-my-account h3.panel-title span {
        left: auto;
        right: 20px;

    }
    .single-my-account h3.panel-title::before {
        right: auto;
        left: 10px;

    }

    .single-my-account .myaccount-info-wrapper{
        text-align: right;
    }
}