.category-container {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;

  .category-image-holder {
    background-color: rgba(#cb2121, 1);
    padding: 28px;
    border-radius: 16px;

    img {
      display: inline-block;
      width: 80px;
      height: auto;
      object-fit: contain;
      transition: transform 0.2s;
    }
    &:hover {
      img {
        transform: scale(1.3);
        transition: 0.5s ease;
      }
    }

    // &:hover{
    //   background-color: transparent;
    // }
    // &:hover img{
    //   transform: scale(1.5);
    // }
  }

  h4 {
    margin-top: 10px;
    font-size: 23px;
  }
}

.trending-dessert {
  h2 {
    font-size: 55px;
    margin-bottom: 50px;
    text-align: center;
  }
}

.subscribe-form {
  .mc-form {
    display: flex;

    & > div {
      flex-grow: 1;
      flex-shrink: 1;

      &:first-child {
        flex-basis: 40%;
        border: 1px solid #c82121;
        border-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        input {
          padding: 20px 12px;
          border: none;
        }
      }

      &:last-child {
        background-color: #c82121;
        text-align: center;
        border-radius: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        button {
          color: #fff !important;
          border: none !important;
          width: 100% !important;
          height: 100% !important;
          margin: 0 !important;
        }
      }
    }
  }
}
