.thank-you-container {
  text-align: center;
  margin: 25vh 0;

  h3 {
    font-size: 60px;
    color: #C82121;
    margin-bottom: 20px;
    line-height: 1;
  }

  p {
    font-size: 30px;
    color: #000;
  }
}